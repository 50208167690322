<template>
	<div class="main-contents gray-back">
		<div class="terms_wrap">
			<div class="title_wrap">
				<h2>하이프로 서비스 정책</h2>
			</div>
			<div class="tab_wrap">
				<div class="tab">
					<ul>
						<li v-for="(item, index) in stipTabList" :key="index" :class="tabIdx == index ? 'active' : ''">
							<a href="javascript:void(0);" @click="tabIdxCng(index)"><span>{{ item }}</span></a>
						</li>
					</ul>
				</div>

				<div class="tab_cont_group">
					<div class="tab_cont" v-for="(item, index) in stipContList" :key="index" :class="tabIdx == index ? 'active' : ''">
						<div class="tab_cont_inner">
							<div v-html="item.stipCont"></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			tabIdx : '0',
			stipTabList : [
				'이용약관',
				'개인정보 처리방침'
			],
			stipContList : []
		}
	},

	beforeMount() {
		var viewNm = this.$route.name;

		/* 탭 변경 */
		switch(viewNm) {
			case 'MAN002M02' :
				this.tabIdx = '1';
				break;
			default:
				this.tabIdx = '0';
				break;
		}

		this.getStipMgrInfo();
	},

	methods: {
		tabIdxCng(idx) {
			this.tabIdx = idx;
			if(idx == 1){
				this.pageMove('MAN002M02');
			}else{
				this.pageMove('MAN002M01');
			}
		},

		getStipMgrInfo(){
			this.$.httpPost('/api/main/getStipContList')
				.then(res => {
					this.stipContList = res.data.stipContList;
			}).catch(this.$.httpErrorCommon);
		},

		pageMove(name) {
			//console.log('name', name);
			this.$router.move(name);
		}
	}
}
</script>